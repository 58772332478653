<template>
  <v-dialog :value="dialog" @input="$emit('input', $event)" max-width="40%" persistent>
    <v-card elevation="1">
      <v-toolbar flat color="primary" light>
        <v-toolbar-title class="white--text">Edit Customer Concern</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-text>
          <v-form class="multi-col-validation">
            <v-row>
              <v-col md="12" cols="12">
                <input type="hidden" v-model="estimate_id" readonly />
                <v-textarea
                  background-color="transparent"
                  v-model="customerConcern.customer_issue"
                  color="secondary"
                  dense
                  label="Customer Issue"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col md="12" cols="12">
                <v-textarea
                  background-color="transparent"
                  v-model="customerConcern.findings"
                  color="secondary"
                  dense
                  label="Findings"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="mt-n5"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded @click.prevent="saveCustomerConcern">
            <v-icon right dark>{{ icons.mdiContentSave }}</v-icon>
            <span>Save</span>
          </v-btn>
          <v-btn color="secondary" rounded @click.prevent="close">
            <v-icon right dark>{{ icons.mdiClose }}</v-icon>
            <span>Cancel</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import { mdiPlus, mdiInformation, mdiContentSave, mdiDeleteForeverOutline, mdiClose, mdiCash } from '@mdi/js'

export default {
  props: {
    dialog: Boolean,
    estimate_id: Number,
    customerConcern: Object,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiCash,
        mdiDeleteForeverOutline,
      },
    }
  },
  methods: {
    saveCustomerConcern: function () {
      this.$store
        .dispatch(`customerConcern/updateNewCustomerConcern`, {
          customer_concern_id: this.customerConcern.customer_concern_id,
          data: this.customerConcern,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record updated successfully',
          })
          window.mitt.emit('loadCustomerConcern', this.close())
        })
        .catch(error => {
          console.log(error)
        })
    },

    deleteInventoryCategory(id) {
      this.close()
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })

        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`inventoryCategory/deleteInventoryCategories`, {
                inv_category_id: id,
              })
              .then(response => {
                window.mitt.emit('loadCustomerConcern', this.close())
              })
          }
        })
    },

    close() {
      this.$emit('close-dialog')
    },
  },
}
</script>

<style>
</style>