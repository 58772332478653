<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="customerconcerns"
      :items-per-page="10"
      :search="search"
      :header-props="{ sortIcon: null }"
      sort-by="customer_issue"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="page-title">
            <v-icon class="secondary--text" left light>{{ icons.mdiAccountAlertOutline }}</v-icon>
            Customer Concern
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="accent" rounded @click.prevent="addnewCustomerConcernModal">
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Add Concern</span>
          </v-btn>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(customerconcern, index1) in items" :key="index1">
            <td>{{ customerconcern.customer_issue }}</td>
            <td>{{ customerconcern.findings }}</td>
            <td>{{ formatDate(customerconcern.created_at) }}</td>
            <td>
              <v-btn
                color="primary"
                @click.prevent="editnewCustomerConcernModal(customerconcern)"
                class="mx-1"
                fab
                x-small
                dark
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
              <v-btn
                @click.prevent="deleteCustomerConcern(customerconcern.customer_concern_id)"
                color="error"
                fab
                x-small
                dark
              >
                <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:no-data>
        <v-btn color="accent" @click="getCustomerConcerns"> Reset </v-btn>
      </template>
    </v-data-table>

    <add-customer-concern
      :estimate_id="estimate_id"
      :dialog.sync="showNewCustomerConcernDialog"
      @open-dialog="showNewCustomerConcernDialog = true"
      @close-dialog="showNewCustomerConcernDialog = false"
    />

    <edit-customer-concern
      :dialog.sync="showEditCustomerConcernDialog"
      :customerConcern="customerConcern"
      @open-dialog="showEditCustomerConcernDialog = true"
      @close-dialog="showEditCustomerConcernDialog = false"
    />
  </v-card>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiAccountMultiple,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiPhone,
  mdiEmail,
  mdiAccountAlertOutline,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
import AddCustomerConcern from '@/components/workflow/customerconcerns/AddNewCustomerConcern.vue'
import EditCustomerConcern from '@/components/workflow/customerconcerns/EditCustomerConcern.vue'

export default {
  props: {
    estimate_id: Number,
  },
  components: {
    AddCustomerConcern,
    EditCustomerConcern,
  },

  created: function () {
    this.getCustomerConcerns()
    window.mitt.on('loadCustomerConcern', () => {
      this.getCustomerConcerns()
    })
  },

  data() {
    return {
      headers: [
        {
          text: 'Customer Concern',
          value: 'customer_issue',
        },
        { text: 'Findings', value: 'findings' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      customerconcerns: [
        {
          customer_concern_id: '',
          estimate_id: '',
          customer_issue: '',
          findings: '',
        },
      ],
      search: '',
      customerConcern: {},
      showNewCustomerConcernDialog: false,
      showEditCustomerConcernDialog: false,

      icons: {
        mdiAccountTie,
        mdiMagnify,
        mdiDotsVertical,
        mdiAccountMultiple,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiPhone,
        mdiEmail,
        mdiAccountAlertOutline,
      },
    }
  },
  methods: {
    getCustomerConcerns: function () {
      let estimateid = localStorage.storedData
      this.$store
        .dispatch('customerConcern/fetchCustomerConcerns', {
          estimate_id: this.$route.params.id || estimateid,
        })
        .then(response => {
          this.customerconcerns = this.fetchCustomerConcerns
        })
        .catch(err => {
          console.log(err)
        })
    },
    deleteCustomerConcern(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`customerConcern/deleteCustomerConcern`, {
                customer_concern_id: id,
              })
              .then(response => {
                this.getCustomerConcerns()
              })
          }
        })
    },

    formatDate(value) {
      return moment(value).format('l')
    },
    addnewCustomerConcernModal: function () {
      this.showNewCustomerConcernDialog = true
    },
    editnewCustomerConcernModal: function (customerConcern) {
      this.customerConcern = Object.assign({}, customerConcern)
      this.showEditCustomerConcernDialog = true
    },
  },

  computed: {
    ...mapGetters({
      fetchCustomerConcerns: 'customerConcern/fetchCustomerConcerns',
    }),
  },
}
</script>

<style>
</style>