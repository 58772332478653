<template>
  <v-card flat>
    <!-- {{ inventory_item }} -->
    <v-form class="multi-col-validation">
      <v-card max-height="350" align="left" class="overflow-y-auto">
        <v-col md="6" cols="12">
          <div class="mb-1">Name</div>
          <v-text-field v-model="inventory.name" color="secondary" label="Name" dense outlined></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <div class="mb-1">Description</div>
          <v-text-field color="secondary" label="Description" dense outlined></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <div class="mb-1">Part Number</div>
          <v-text-field color="secondary" label="Part Number" dense outlined></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <div class="mb-1">Bin Location</div>
          <v-text-field color="secondary" label="Bin Location" dense outlined></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <div class="mb-1">Inventory Category</div>
          <v-text-field color="secondary" label="Inventory Category" dense outlined></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <div class="mb-1">Vendor</div>
          <v-text-field color="secondary" label="Vendor" dense outlined></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <div class="mb-1">Quantity</div>
          <v-text-field color="secondary" label="Quantity" dense outlined></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <div class="mb-1">Quantity on hand</div>
          <v-text-field color="secondary" label="Quantity on hand" dense outlined></v-text-field>
        </v-col>
      </v-card>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    inventory_item: Number,
  },
  components: {},

  created: function () {
    this.getInventoryItems()
  },

  data() {
    return {
      inventory: {},
      itemtype: [
        {
          item_type: 'Part',
        },
        {
          item_type: 'Labor',
        },
        {
          item_type: 'Tire',
        },
        {
          item_type: 'Subcontractor',
        },
        {
          item_type: 'Fee',
        },
      ],
    }
  },
  methods: {
    getInventoryItems: function () {
      this.$store
        .dispatch('item/getItemById', {
          item_id: this.inventory_item,
        })
        .then(response => {
          this.inventory = this.getItemsById
        })
        .catch(err => {
          console.log(err)
        })
    },
  },

  computed: {
    ...mapGetters({
      fetchInventories: 'inventory/fetchInventories',
      getItemsById: 'item/getItemsById',
    }),
  },

}
</script>

<style>
</style>