<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="technicianconcerns"
      :items-per-page="10"
      :search="search"
      :header-props="{ sortIcon: null }"
      sort-by="inspection_task"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="page-title">
            <v-icon class="secondary--text" left light>{{ icons.mdiAccountAlertOutline }}</v-icon>
            Technician Concern
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="accent" rounded @click.prevent="addnewTechnicianConcernModal">
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Add Concern</span>
          </v-btn>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(technicianConcern, index1) in items" :key="index1">
            <td>
              <v-icon :color="technicianConcern.color" class="mr-1">{{ technicianConcern.icon }}</v-icon
              >{{ technicianConcern.rating }}
            </td>
            <td>{{ technicianConcern.inspection_task }}</td>
            <td>{{ technicianConcern.findings }}</td>
            <td>{{ formatDate(technicianConcern.created_at) }}</td>
            <td>
              <v-btn
                color="primary"
                @click.prevent="editnewTechnicianConcernModal(technicianConcern)"
                class="mx-1"
                fab
                x-small
                dark
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
              <v-btn
                @click.prevent="deleteTechnicianConcern(technicianConcern.tech_concern_id)"
                color="error"
                fab
                x-small
                dark
              >
                <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:no-data>
        <v-btn color="accent" @click="getTechnicianConcerns"> Reset </v-btn>
      </template>
    </v-data-table>

    <add-technician-concern
      :estimate_id="estimate_id"
      :dialog.sync="showNewTechnicianConcernDialog"
      @open-dialog="showNewTechnicianConcernDialog = true"
      @close-dialog="showNewTechnicianConcernDialog = false"
    />
    <edit-technician-concern
      :dialog.sync="showEditTechnicianConcernDialog"
      :technicianConcern="technicianConcern"
      @open-dialog="showEditTechnicianConcernDialog = true"
      @close-dialog="showEditTechnicianConcernDialog = false"
    />
  </v-card>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiAccountMultiple,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiPhone,
  mdiEmail,
  mdiAccountAlertOutline,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
import AddTechnicianConcern from '@/components/workflow/technicianconcerns/AddNewTechnicianConcern.vue'
import EditTechnicianConcern from '@/components/workflow/technicianconcerns/EditNewTechnicianConcern.vue'

export default {
  props: {
    estimate_id: Number,
  },
  components: {
    AddTechnicianConcern,
    EditTechnicianConcern,
  },

  created: function () {
    this.getTechnicianConcerns()
    window.mitt.on('loadTechnicianConcern', () => {
      this.getTechnicianConcerns()
    })
  },

  data() {
    return {
      headers: [
        {
          text: 'Rating',
          value: 'rating',
        },
        { text: 'Inspection Task', value: 'inspection_task' },
        { text: 'Findings', value: 'findings' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      technicianconcerns: [
        {
          tech_concern_id: '',
          estimate_id: '',
          rating: '',
          inspection_task: '',
          findings: '',
        },
      ],

      technicianConcern: {},
      search: '',
      showNewTechnicianConcernDialog: false,
      showEditTechnicianConcernDialog: false,

      icons: {
        mdiAccountTie,
        mdiMagnify,
        mdiDotsVertical,
        mdiAccountMultiple,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiPhone,
        mdiEmail,
        mdiAccountAlertOutline,
      },
    }
  },
  methods: {
    getTechnicianConcerns: function () {
      let estimateid = localStorage.storedData
      this.$store
        .dispatch('technicianConcern/fetchTechnicianConcerns', {
          estimate_id: this.$route.params.id || estimateid,
        })
        .then(response => {
          this.technicianconcerns = this.fetchTechnicianConcerns
        })
        .catch(err => {
          console.log(err)
        })
    },
    deleteTechnicianConcern(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`technicianConcern/deleteTechnicianConcern`, {
                tech_concern_id: id,
              })
              .then(response => {
                this.getTechnicianConcerns()
              })
          }
        })
    },

    formatDate(value) {
      return moment(value).format('l')
    },
    addnewTechnicianConcernModal: function () {
      this.showNewTechnicianConcernDialog = true
    },
    editnewTechnicianConcernModal: function (technicianConcern) {
      this.technicianConcern = Object.assign({}, technicianConcern)
      this.showEditTechnicianConcernDialog = true
    },
  },

  computed: {
    ...mapGetters({
      fetchTechnicianConcerns: 'technicianConcern/fetchTechnicianConcerns',
    }),
  },
}
</script>

<style>
</style>