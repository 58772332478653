<template>
  <v-dialog :value="dialog" @input="$emit('input', $event)" max-width="40%" persistent>
    <v-card elevation="1">
      <v-toolbar flat color="primary" light>
        <v-toolbar-title class="white--text">Add New Technician Concern</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-text>
          <v-form class="multi-col-validation">
            <v-row>
              <v-col md="12" cols="12">
                <v-autocomplete
                  v-model="technicianConcern.rating"
                  :items="items"
                  item-value="rating"
                  item-text="rating"
                  outlined
                  color="secondary"
                  dense
                  chips
                  deletable-chips
                  small-chips
                  label="Rating"
                  single-line
                  @change="getColorandIcons(technicianConcern.rating)"
                >
                  <template v-slot:item="{ item }">
                    <v-icon :style="{ color: item.color }" class="mr-1">{{ item.icon }}</v-icon
                    ><span>{{ item.rating }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="12" cols="12">
                <input type="hidden" v-model="estimate_id" readonly />
                <input type="hidden" v-model="technicianConcern.color" readonly />
                <input type="hidden" v-model="technicianConcern.icon" readonly />
                <v-textarea
                  background-color="transparent"
                  v-model="technicianConcern.inspection_task"
                  color="secondary"
                  dense
                  label="Inspection Task"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col md="12" cols="12">
                <v-textarea
                  background-color="transparent"
                  v-model="technicianConcern.findings"
                  color="secondary"
                  dense
                  label="Findings"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="mt-n5"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded @click.prevent="addNewTechnicianConcern">
            <v-icon right dark>{{ icons.mdiContentSave }}</v-icon>
            <span>Save</span>
          </v-btn>
          <v-btn color="secondary" rounded @click.prevent="close">
            <v-icon right dark>{{ icons.mdiClose }}</v-icon>
            <span>Cancel</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import { mdiPlus, mdiInformation, mdiContentSave, mdiDeleteForeverOutline, mdiClose, mdiCash } from '@mdi/js'

export default {
  props: {
    dialog: Boolean,
    estimate_id: Number,
  },
  data() {
    return {
      technicianConcern: {
        rating: '',
        inspection_task: '',
        findings: '',
        color: '',
        icon: '',
      },
      items: [
        {
          rating: 'Check & Okay',
          color: '#00FF00',
          icon: 'mdi-check-circle-outline',
        },
        {
          rating: 'May need attention',
          color: '#00FFFF',
          icon: 'mdi-information',
        },
        {
          rating: 'Needs immediate attention',
          color: '#FF0000',
          icon: 'mdi-close-thick',
        },
        {
          rating: 'Not applicable',
          color: '#808080',
          icon: 'mdi-minus-circle',
        },
      ],
      icons: {
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiCash,
        mdiDeleteForeverOutline,
      },
    }
  },
  methods: {
    addNewTechnicianConcern: function () {
      this.$store
        .dispatch(`technicianConcern/storeNewTechnicianConcern`, {
          estimate_id: this.estimate_id,
          rating: this.technicianConcern.rating,
          inspection_task: this.technicianConcern.inspection_task,
          findings: this.technicianConcern.findings,
          color: this.technicianConcern.color,
          icon: this.technicianConcern.icon,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record added successfully',
          })

          window.mitt.emit('loadTechnicianConcern', this.close())
        })
        .catch(error => {
          console.log(error)
        })
    },
    getColorandIcons(value) {
      if (value === 'Check & Okay') {
        this.technicianConcern.color = '#00FF00'
        this.technicianConcern.icon = 'mdi-check-circle-outline'
      } else if (value === 'May need attention') {
        this.technicianConcern.color = '#00FFFF'
        this.technicianConcern.icon = 'mdi-information'
      } else if (value === 'Needs immediate attention') {
        this.technicianConcern.color = '#FF0000'
        this.technicianConcern.icon = 'mdi-close-thick'
      } else if (value === 'Not applicable') {
        this.technicianConcern.color = '#808080'
        this.technicianConcern.icon = 'mdi-minus-circle'
      }
    },

    resetForm: function () {
      this.technicianConcern = {}
    },
    close() {
      this.resetForm()
      this.$emit('close-dialog')
    },
  },
}
</script>

<style>
</style>