<template>
  <v-dialog
    :value="dialog"
    @input="$emit('input', $event)"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card elevation="1">
      <v-toolbar height="45" color="primary">
        <v-toolbar-title class="white--text">Search Canned Service</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-tabs horizontal>
        <v-tab> All </v-tab>
        <v-tab> Canned </v-tab>

        <v-tab-item>
          <v-divider></v-divider>
          <v-card flat>
            <v-row>
              <v-col md="12" cols="12">
                <input type="hidden" name="" v-model="estimate_id" />
                <v-card class="mb-2" elevation="1">
                  <v-toolbar flat>
                    <v-toolbar-title class="page-title">
                      <v-icon class="secondary--text" left light>{{ icons.mdiWrenchOutline }}</v-icon>
                      Canned Services
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>

                    <v-spacer></v-spacer>
                    <v-btn color="accent" rounded @click.prevent="$router.push({ name: 'addnewcannedservice' })">
                      <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                      <span>Create New</span>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-divider class="mb-2"></v-divider>
                    <v-text-field
                      color="accent"
                      class="mx-2"
                      v-model="searchcannedservice_all"
                      :append-icon="icons.mdiMagnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-data-table
                      :headers="headerSearchCannedService"
                      :items="searchCannedServicesAll"
                      item-value="cannedservice_name"
                      item-key="service_id"
                      :search="searchcannedservice_all"
                      :expanded.sync="expanded"
                      show-expand
                      class="elevation-1"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click.prevent="addService(item.service_id)"
                              dark
                              class="mx-1"
                              fab
                              x-small
                            >
                              <v-icon size="20">{{ icons.mdiPlus }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Add Service</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th>Item Type</th>
                                  <th>Name</th>
                                  <th>Price</th>
                                  <th>SubTotal</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(cannedserviceitemAll, index) in item.cannedservice_items" :key="index">
                                  <td>{{ cannedserviceitemAll.item_type }}</td>
                                  <td>{{ cannedserviceitemAll.name }}</td>
                                  <td>{{ cannedserviceitemAll.retail_price }}</td>
                                  <td>{{ cannedserviceitemAll.subtotal }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </td>
                      </template>
                    </v-data-table>
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-divider></v-divider>
          <v-card flat>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-card class="mb-2" elevation="1">
                  <v-toolbar flat>
                    <v-toolbar-title class="page-title">
                      <v-icon class="secondary--text" left light>{{ icons.mdiArchiveArrowUpOutline }}</v-icon>
                      Canned Categories
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-2" rounded @click.prevent="addnewCannedServiceCategory" color="primary"
                      ><v-icon class="mr-1">{{ icons.mdiPlus }}</v-icon
                      >Create New
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-divider class="mb-2"></v-divider>
                    <v-text-field
                      color="accent"
                      class="mx-2"
                      v-model="searchcannedcategory"
                      :append-icon="icons.mdiMagnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-data-table
                      :headers="headerCategories"
                      :items="cannedservice_categories"
                      :items-per-page="5"
                      :search="searchcannedcategory"
                      sort-by="category_name"
                      class="elevation-1"
                    >
                      <template v-slot:body="{ items }">
                        <tbody>
                          <tr v-for="(cannedCategory, index) in items" :key="index">
                            <td>
                              {{ cannedCategory.name }}
                            </td>
                            <td>
                              <v-menu offset-y bottom right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn light icon v-bind="attrs" v-on="on">
                                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                                  </v-btn>
                                </template>

                                <v-list>
                                  <v-list-item @click.prevent="editCannedServiceCategory(cannedCategory)">
                                    <v-list-item-icon>
                                      <v-icon color="primary" class="mx-1">{{ icons.mdiPencil }}</v-icon>
                                      <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item-icon>
                                  </v-list-item>
                                  <v-list-item
                                    @click.prevent="deleteCannedServiceCategory(cannedCategory.cann_service_cat_id)"
                                  >
                                    <v-list-item-icon>
                                      <v-icon color="error" class="mx-1"> {{ icons.mdiClose }}</v-icon>
                                      <v-list-item-title>Remove</v-list-item-title>
                                    </v-list-item-icon>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                      <template v-slot:no-data>
                        <v-btn color="accent" @click="getCannedSeviceCategories"> Reset </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-card class="mb-2" elevation="1">
                  <v-toolbar flat>
                    <v-toolbar-title class="page-title">
                      <v-icon class="secondary--text" left light>{{ icons.mdiWrenchOutline }}</v-icon>
                      Canned Services
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" rounded @click.prevent="$router.push({ name: 'addnewcannedservice' })">
                      <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                      <span>Create New</span>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-divider class="mb-2"></v-divider>
                    <v-text-field
                      color="accent"
                      class="mx-2"
                      v-model="searchcannedserviceid"
                      :append-icon="icons.mdiMagnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-data-table
                      :headers="headerSearchCannedService"
                      :items="searchCannedServices"
                      :items-per-page="5"
                      :search="searchcannedserviceid"
                      :expanded.sync="expanded"
                      show-expand
                      sort-by="canned_category_name"
                      class="elevation-1"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click.prevent="addService(item.service_id)"
                              dark
                              class="mx-1"
                              fab
                              x-small
                            >
                              <v-icon size="20">{{ icons.mdiPlus }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Add Service</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th>Item Type</th>
                                  <th>Name</th>
                                  <th>Price</th>
                                  <th>SubTotal</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(searchCannedServices, index) in item.cannedservice_items" :key="index">
                                  <td>{{ searchCannedServices.item_type }}</td>
                                  <td>{{ searchCannedServices.name }}</td>
                                  <td>{{ searchCannedServices.retail_price }}</td>
                                  <td>{{ searchCannedServices.subtotal }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </td>
                      </template>
                      <template v-slot:no-data>
                        <v-btn color="accent" @click="getSearchCannedSevice"> Reset </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-divider></v-divider>
      <addcannedservice-category
        :dialog.sync="showNewCannedServiceCategoryDialog"
        @open-dialog="showNewCannedServiceCategoryDialog = true"
        @close-dialog="showNewCannedServiceCategoryDialog = false"
      />

      <editcannedservice-category
        :dialog.sync="showEditCannedServiceCategoryDialog"
        :cannedServiceCategory="cannedServiceCategory"
        @open-dialog="showEditCannedServiceCategoryDialog = true"
        @close-dialog="showEditCannedServiceCategoryDialog = false"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import AddNewCannedServiceCategory from '@/views/cannedservice/AddNewCannedServiceCategory.vue'
import EditCannedServiceCategory from '@/views/cannedservice/EditCannedServiceCategory.vue'
import mitt from 'mitt'
import { mapGetters } from 'vuex'
window.mitt = window.mitt || new mitt()
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiToolbox,
  mdiPencil,
  mdiClose,
  mdiWrenchOutline,
  mdiArchiveArrowUpOutline,
} from '@mdi/js'

export default {
  props: {
    estimate_id: Number,
    dialog: Boolean,
  },
  components: {
    'addcannedservice-category': AddNewCannedServiceCategory,
    'editcannedservice-category': EditCannedServiceCategory,
  },
  created: function () {
    this.getCannedSeviceCategories()
    this.getSearchCannedSevice()
    this.getSearchCannedSeviceAll()
    window.mitt.on('loadCannedCategories', () => {
      this.getCannedSeviceCategories()
      this.getSearchCannedSevice()
      this.getSearchCannedSeviceAll()
    })
  },
  data() {
    return {
      headerCategories: [
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headerSearchCannedService: [
        {
          text: 'Canned Category',
          value: 'canned_category_name',
        },
        {
          text: 'Name',
          value: 'cannedservice_name',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headerSearchCannedServiceItem: [
        {
          text: 'Item Type',
          value: 'item_type',
        },
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      canned_id: 'All',
      expanded: [],
      cannedItemExpanded: [],
      searchcannedcategory: '',
      searchcannedserviceid: '',
      searchcannedservice_all: '',
      cannedservice_categories: [],
      searchCannedServices: [],
      showNewCannedServiceCategoryDialog: false,
      showEditCannedServiceCategoryDialog: false,
      searchCannedServicesAll: [],
      cannedServiceCategory: {
        name: '',
      },
      inventoryCategory: {
        name,
      },
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiToolbox,
        mdiPencil,
        mdiClose,
        mdiWrenchOutline,
        mdiArchiveArrowUpOutline,
      },
    }
  },
  methods: {
    getCannedSeviceCategories: function () {
      this.$store
        .dispatch('cannedService/fetchCannedServicesCategories')
        .then(response => {
          this.cannedservice_categories = this.fetchCannedServicesCategories
        })
        .catch(err => {
          console.log(err)
        })
    },

    getSearchCannedSevice: function () {
      this.$store
        .dispatch('cannedService/searchCannedServices', this.canned_id)
        .then(response => {
          this.searchCannedServices = this.fetchSearchCannedServices
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSearchCannedSeviceAll: function () {
      this.$store
        .dispatch('cannedService/searchCannedServicesAll')
        .then(response => {
          this.searchCannedServicesAll = this.fetchCannedServicesAll
        })
        .catch(err => {
          console.log(err)
        })
    },

    addService: function (id) {
      this.$store
        .dispatch(`cannedService/addservice`, {
          service_id: id,
          data: {
            service_id: id,
            estimate_id: this.estimate_id,
          },
        })
        .then(response => {})
        .catch(err => {
          console.log(err)
        })
    },

    saveInventoryCategory: function () {
      this.$store
        .dispatch(`inventoryCategory/storeNewInventoryCategories`, this.inventoryCategory)
        .then(response => {
          window.mitt.emit('loadInventoryCategories', this.close())
        })
        .catch(error => {
          console.log(error)
        })
    },
    resetForm: function () {
      this.inventoryCategory = {}
    },
    close() {
      this.resetForm()
      this.$emit('close-dialog')
      window.mitt.emit('loadServices')
    },

    addnewCannedServiceCategory: function () {
      this.showNewCannedServiceCategoryDialog = true
    },

    editCannedServiceCategory: function (cannedServiceCategory) {
      this.cannedServiceCategory = Object.assign({}, cannedServiceCategory)
      this.showEditCannedServiceCategoryDialog = true
    },
  },

  computed: {
    ...mapGetters({
      fetchSearchCannedServices: 'cannedService/fetchSearchCannedServices',
      fetchCannedServicesCategories: 'cannedService/fetchCannedServicesCategories',
      fetchCannedServicesAll: 'cannedService/fetchSearchCannedServicesAll',
    }),
  },
}
</script>

<style>
</style>