<template>
  <v-card elevation="1">
    <v-layout v-for="(imagevault, index) in image_vaults" :key="index">
      <v-file-input
        v-model="imagevault.images"
        small-chips
        :show-size="1000"
        counter
        outlined
        placeholder="Select your files"
        multiple
        label="Add files"
        @change="Preview_image(index)"
      >
        <template v-slot:selection="{ text, index }">
          <v-chip small text-color="white" color="#295671" close @click:close="remove(index)">
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      image_vaults: [
        {
          image_id: '',
          inspection_item_id: '',
          images: [],
        },
      ],
      previewList: [],
      imageList: [],

      image_vault: {
        image_id: '',
        inspection_item_id: '',
        filename: [],
        path: '',
      },
    }
  },
  methods: {
    Preview_image(index) {
      console.log('files', this.image_vaults[index].images)
      let currentObj = this
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      let formData = new FormData()

      //formData.append('image_vaults', this.image_vault)
      // append files
      // for (let i = 0; i < this.image_vaults.images.length; i++) {
      //   formData.append('image_vaults[]', this.image_vaults[index].images[i])
      // }
      //send upload request
      // this.$store
      //   .dispatch(`inspection/storeNewImageVault`, formData, config)
      //   .then(function (response) {
      //     currentObj.success = response.data.success
      //     currentObj.filename = ''
      //   })
      //   .catch(function (error) {
      //     currentObj.output = error
      //   })
    },
  },

  uploadImage: function () {
    let currentObj = this
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }
    let formData = new FormData()

    // append files
    for (let i = 0; i < this.image_vaults.length; i++) {
      formData.append('image_vaults', this.image_vaults[i])
    }
    //send upload request
    this.$store
      .dispatch(`inspection/storeNewImageVault`, formData, config)
      .then(function (response) {
        currentObj.success = response.data.success
        currentObj.filename = ''
      })
      .catch(function (error) {
        currentObj.output = error
      })
  },

  remove:function() {
     this.image_vaults.splice(index, 1)
  }
}
</script>

<style>
</style>