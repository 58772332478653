<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="clocks"
      :items-per-page="10"
      :search="search"
      sort-by="first_name"
      item-key="clock_id"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="page-title">
            <v-icon class="secondary--text" left light>{{ icons.mdiClockTimeFourOutline }}</v-icon>
            Timesheets
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(clock, index) in items" :key="index">
            <td>
              <v-icon class="mr-1">{{ icons.mdiAccountTie }}</v-icon
              >{{ clock.first_name }}
            </td>
            <td>{{ clock.last_name }}</td>
            <td>{{ clock.activity }}</td>
            <td>{{ clock.notes }}</td>
            <td>{{ clock.date_in }}</td>
            <td>{{ clock.time_in }}</td>
            <td>{{ clock.date_out }}</td>
            <td>{{ clock.time_out }}</td>
            <td>
              <v-btn
                color="primary"
                @click.prevent="
                  $router.push({
                    name: 'editcustomer',
                    params: { id: customer.customer_id },
                  })
                "
                class="mx-1"
                fab
                x-small
                dark
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
              <v-btn @click.prevent="" color="error" fab x-small dark>
                <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>

      <template v-slot:no-data>
        <v-btn color="accent" @click="getClocks"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiClockTimeFourOutline,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {},

  created: function () {
    this.getClocks()
    setInterval(() => {
      this.formatDate(), 1 * 1000
    })
  },
  data() {
    return {
      headers: [
        {
          text: 'First Name',
          value: 'first_name',
        },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Activity', value: 'activity' },
        { text: 'Note', value: 'notes' },
        { text: 'Date', value: 'date_in' },
        { text: 'Time In', value: 'time_in' },
        { text: 'Date', value: 'date_out' },
        { text: 'Time Out', value: 'time_out' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      clocks: [],
      search: '',
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiClockTimeFourOutline,
      },
    }
  },
  methods: {
    getClocks: function () {
      this.$store
        .dispatch('clock/fetchTimesheets')
        .then(response => {
          this.clocks = this.fetchTimesheets
        })
        .catch(err => {
          console.log(err)
        })
    },
    formatDate(value) {
      return moment(value).format('l LTS')
    },
  },

  computed: {
    ...mapGetters({
      fetchTimesheets: 'clock/fetchTimesheets',
    }),
  },
}
</script>

<style>
</style>